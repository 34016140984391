import { Controller } from "~/controllers";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("[data-leader]").forEach((leader) => {
      leader.addEventListener("change", this.syncFollowers);
    });
  }

  syncFollowers = (event) => {
    const key = event.target.getAttribute("data-leader");
    this.element.querySelectorAll("[data-follower]").forEach((follower) => {
      if (follower.getAttribute("data-follower") === key) {
        follower.setAttribute("data-checked", "");
      } else {
        follower.removeAttribute("data-checked");
      }
    });
  };
}
