import { Controller } from "~/controllers";
import { createPopover } from "~/util/html/popover";

const CLOSE_EVENT = "close";
const OPEN_EVENT = "open";

export class PopoverController extends Controller {
  static targets = ["trigger", "popover", "focus"];
  static values = { placement: String };

  static get onClose() {
    return `${this.identifier}:${CLOSE_EVENT}`;
  }

  static get onOpen() {
    return `${this.identifier}:${OPEN_EVENT}`;
  }

  connect = () => {
    this.isOpen = false;
    this.addEventListeners();
  };

  disconnect = () => {
    document.removeEventListener("click", this.handleClickOutside);
  };

  addEventListeners = () => {
    document.addEventListener("click", this.handleClickOutside);
  };

  togglePopover = (event) => {
    if (this.isOpen) {
      this.closePopover();
      return;
    }

    if (event.target === this.triggerTarget) {
      return;
    }

    this.openPopover();
  };

  openPopover = () => {
    this.isOpen = true;
    this.triggerTarget.setAttribute("aria-expanded", "true");
    this.popoverTarget.classList.remove("hidden");

    this.cleanup = createPopover({
      trigger: this.triggerTarget,
      popover: this.popoverTarget,
      config: { placement: this.placementValue },
    });

    if (this.hasFocusTarget) {
      if (this.focusTarget.value) {
        this.focusTarget.value = "";
        this.focusTarget.dispatchEvent(new Event("input"));
      }
      this.focusTarget.focus();
    }
    this.dispatch(OPEN_EVENT);
  };

  closePopover = () => {
    this.isOpen = false;
    this.popoverTarget.classList.add("hidden");
    this.triggerTarget.setAttribute("aria-expanded", "false");
    this.cleanup?.();
    this.dispatch(CLOSE_EVENT);
  };

  handleClickOutside = (event) => {
    if (
      this.isOpen &&
      !this.popoverTarget.contains(event.target) &&
      !this.triggerTarget.contains(event.target)
    ) {
      this.closePopover();
    }
  };
}

export default PopoverController;
