import { Controller } from "~/controllers";
import Inputmask from "inputmask";

function makeMoneyMask(options = {}) {
  return Inputmask({
    alias: "decimal",
    digits: options.dollarOnly ? "0" : "2",
    clearMaskOnLostFocus: false,
    placeholder: "",
    rightAlign: false,
    allowMinus: false,
    // TODO: add this back when invoice/payment code is cleaned up
    // the current stimulus based parsing is not accounting for commas
    // groupSeparator: ",",
    removeMaskOnSubmit: true,
  });
}

function makePhoneMask() {
  return Inputmask({
    regex: "\\+?[0-9\\(\\)\\-\\s]+",
    clearMaskOnLostFocus: false,
    showMaskOnHover: false,
    placeholder: "",
  });
}

function makeMask(type, options = {}) {
  switch (type) {
    case "money":
      return makeMoneyMask(options);
    case "phone":
      return makePhoneMask(options);
  }
}

export default class extends Controller {
  static values = {
    type: String,
    options: Object,
  };

  connect = () => {
    makeMask(this.typeValue, this.optionsValue).mask(this.element);
  };
}
