import { Controller } from "~/controllers";

export default class extends Controller {
  connect() {
    if (!this.hasMarkedAsRead) {
      this.markAsRead();
      this.hasMarkedAsRead = true;
    }
  }

  markAsRead() {
    const conversationId = this.element.dataset.conversationId;
    if (!conversationId) return;

    fetch(`/messages/conversation/${conversationId}/mark_as_read`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
    });
  }
}
