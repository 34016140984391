import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["hiddenInput", "search", "list"];

  connect = () => {
    this.filteredOptions = [...this.listTarget.querySelectorAll("li")];
  };

  select = (event) => {
    const selectedOption = event.params.value;
    this.hiddenInputTarget.value = selectedOption;
    this.hiddenInputTarget.disabled = false;
  };

  search = (event) => {
    this.filterOptions(event.target.value);
  };

  filterOptions = (value) => {
    this.filteredOptions.forEach((option) => {
      const text = option.textContent.toLowerCase();
      const match = text.includes(value.toLowerCase());
      option.style.display = match ? "block" : "none";
    });
  };
}
