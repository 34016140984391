// Reconnect ActionCable after switching accounts

import { Controller } from "~/controllers";
import consumer from "../channels/consumer";

export default class extends Controller {
  reconnect() {
    if (consumer.connection.isActive()) {
      consumer.connection.reopen();
    }
  }
}
