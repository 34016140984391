import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["tab", "panel", "selectedIndexInput"];
  static values = {
    initialIndex: Number,
  };

  connect = () => {
    this.showTab(this.initialIndexValue);
  };

  switchTab = (event) => {
    this.showTab(parseInt(event.params["index"]));
  };

  showTab = (index) => {
    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.setAttribute("aria-selected", "true");
        tab.setAttribute("disabled", "");
      } else {
        tab.setAttribute("aria-selected", "false");
        tab.removeAttribute("disabled");
      }
    });

    this.panelTargets.forEach((panel, i) => {
      if (i === index) {
        panel.classList.remove("hidden");
      } else {
        panel.classList.add("hidden");
      }
    });

    if (this.hasSelectedIndexInputTarget) {
      this.selectedIndexInputTarget.value = index;
    }
  };
}
