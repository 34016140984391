import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["input"];

  change = (event) => {
    const input = event.params.inputId
      ? document.getElementById(event.params.inputId)
      : this.inputTarget;
    input.value = event.params.value;
    input.dispatchEvent(new Event("input", { bubbles: true }));
    input.dispatchEvent(new Event("change", { bubbles: true }));
  };
}
