import { Controller } from "~/controllers";
import { isSamePage } from "~/util/url";

export default class extends Controller {
  connect = () => {
    this.updateCurrentPage();
    document.addEventListener("turbo:load", this.updateCurrentPage);
  };

  disconnect = () => {
    document.removeEventListener("turbo:load", this.updateCurrentPage);
  };

  updateCurrentPage = (event) => {
    const url = event?.detail?.url || window.location.href;
    if (isSamePage(url, this.element.href)) {
      this.element.setAttribute("aria-current", "page");
    } else {
      this.element.removeAttribute("aria-current");
    }
  };
}
