import { Tabs, Toggle } from "tailwindcss-stimulus-components";

import { application } from "./application";

function registerControllers(controllers, start, end) {
  for (const [relativeName, mod] of Object.entries(controllers)) {
    application.register(
      // https://stimulus.hotwired.dev/reference/controllers#identifiers
      relativeName.slice(start, -end).replaceAll("_", "-").replaceAll("/", "--"),
      mod.default,
    );
  }
}

function registerJSControllers(glob, prefix) {
  // `_controller.js` part of the filename is 14 length
  registerControllers(glob, prefix.length, 14);
}

function registerTSXControllers(glob, prefix) {
  // `_controller.tsx` part of the filename is 15 length
  registerControllers(glob, prefix.length, 15);
}

registerJSControllers(import.meta.glob("./**/*_controller.js", { eager: true }), "./");
registerTSXControllers(
  import.meta.glob("~/controllers/**/*_controller.tsx", { eager: true }),
  "/controllers/",
);
registerJSControllers(
  import.meta.glob("../../components/**/*_controller.js", { eager: true }),
  "../../components/",
);

application.register("tabs", Tabs);
application.register("toggle", Toggle);
