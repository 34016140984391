import { Controller } from "~/controllers";

export default class extends Controller {
  connect() {
    document.addEventListener("click", this.handleDocumentClick);
  }

  disconnect() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.element.dispatchEvent(new CustomEvent("click-outside"));
    }
  };
}
