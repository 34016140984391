export function inertOtherBodyChildren(container: HTMLElement) {
  const original = new Map<HTMLElement, { ariaHidden: null | string; inert: boolean }>();
  for (const child of document.body.children) {
    if (child !== container && child instanceof HTMLElement) {
      original.set(child, {
        ariaHidden: child.getAttribute("aria-hidden"),
        inert: child.inert,
      });
      child.setAttribute("aria-hidden", "true");
      child.inert = true;
    }
  }

  return () => {
    for (const [child, originalAttributes] of original.entries()) {
      child.inert = originalAttributes.inert;
      if (originalAttributes.ariaHidden === null) {
        child.removeAttribute("aria-hidden");
      } else {
        child.setAttribute("aria-hidden", originalAttributes.ariaHidden);
      }
    }
  };
}
