const FOCUS_SELECTOR = [
  "[contenteditable]:not([contenteditable='false'])",
  "[tabindex]",
  "audio[controls]",
  "video[controls]",
  "a[href]",
  "button:not([disabled])",
  "input:not([disabled],[type='hidden'])",
  "select:not([disabled])",
  "textarea:not([disabled])",
]
  .map((selector) => `${selector}:not([tabindex='-1'],.hidden)`)
  .join(",");

export function getFocusableElementsWithin(container: HTMLElement): NodeListOf<HTMLElement> {
  return container.querySelectorAll<HTMLElement>(FOCUS_SELECTOR);
}

function getNextLoopedFocusElement(container: HTMLElement, reverse: boolean) {
  const originalElement = document.activeElement as HTMLElement | null;
  if (!originalElement) {
    return;
  }
  const focusable = getFocusableElementsWithin(container);
  const [first] = focusable;
  const last = focusable[focusable.length - 1];
  if (reverse && originalElement === first) {
    return last;
  } else if (!reverse && originalElement === last) {
    return first;
  }
}

export function addFocusTrapEventListener(container: () => HTMLElement) {
  function focusTrapHandler(event: KeyboardEvent) {
    if (event.key !== "Tab") {
      return;
    }
    const loopedFocusElement = getNextLoopedFocusElement(container(), event.shiftKey);
    if (loopedFocusElement) {
      // Its possible this focus won't succeed. Technically this means we need to again with
      // the next next element and so on. For simplicity, we skip this edge case.
      loopedFocusElement.focus();
      event.preventDefault();
    }
  }
  window.document.addEventListener("keydown", focusTrapHandler);
  return () => {
    window.document.removeEventListener("keydown", focusTrapHandler);
  };
}
