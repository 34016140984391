import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["toast"];

  trackedIds = [];

  connect = () => {
    this.toastTargets.forEach(this.initToast);
    this.element.addEventListener("turbo:before-morph-element", this.reconnect);
  };

  reconnect = () => {
    this.toastTargets.forEach(this.initToast);
  };

  toastTargetConnected = (toast) => {
    this.initToast(toast);
  };

  initToast = (toast) => {
    if (this.trackedIds.includes(toast.id)) return;

    // in timeout to ensure initial opacity is rendered before these classes are added (prevent batching)
    setTimeout(() => {
      toast.classList.remove("opacity-0");
      toast.classList.add("opacity-100");
    }, 0);

    // TODO: pause countdown when mouse hovers over any toast, show countdown progress bar below toast
    setTimeout(() => this.removeToastById(toast.id), 8000);
    this.trackedIds.push(toast.id);
  };

  removeToastById = (id) => {
    this.trackedIds = this.trackedIds.filter((trackedId) => trackedId !== id);
    const toast = document.getElementById(id);
    if (!toast) return;

    toast.classList.remove("opacity-100");
    toast.classList.add("opacity-0");
    setTimeout(() => toast.remove(), 300);
  };

  removeToast = (event) => {
    const toast = event.target.closest("[data-toast-manager-target='toast']");
    this.removeToastById(toast.id);
  };
}
