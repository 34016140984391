// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Controller as StimulusController } from "@hotwired/stimulus";
import type { JSXElement } from "solid-js";
import { render } from "solid-js/web";

import { TwelveEntrypointWrapper } from "~/util/entrypoint";

export abstract class Controller<Elem extends Element = Element> extends StimulusController<Elem> {
  static identifier: string;

  static afterLoad(identifier: string) {
    this.identifier = identifier; // expose identifier on class
  }
}

export abstract class SolidController<Elem extends Element = Element> extends Controller<Elem> {
  dispose: (() => void) | null = null;

  connect() {
    this.dispose = render(
      () => <TwelveEntrypointWrapper entry={this.root.bind(this)} />,
      this.element,
    );
  }

  disconnect(): void {
    this.dispose!();
    this.dispose = null;
  }

  abstract root(): JSXElement;
}
