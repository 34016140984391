import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["checkNumberContainer", "checkNumber", "paymentType"];

  typeChange() {
    if (this.paymentTypeTarget.value === "Check") {
      this.checkNumberContainerTarget.classList.remove("hidden");
    } else {
      this.checkNumberContainerTarget.classList.add("hidden");
      this.checkNumberTarget.value = "";
    }
  }
}
