import { Controller } from "~/controllers";
import Pikaday from "pikaday";

export default class extends Controller {
  static targets = ["input"];
  static values = { id: String };

  connect() {
    this.picker = new Pikaday({
      field: this.inputTarget,
      trigger: this.element,
      onSelect: (date) => {
        this.dispatchWithPrefix("date-selected", { detail: { date } });
      },
      position: "bottom center",
      theme: "datepicker",
    });

    this.dispatchWithPrefix("connected");
  }

  dispatchWithPrefix(eventName, options) {
    this.dispatch(eventName, {
      ...options,
      prefix: `${this.identifier}/${this.idValue}`,
    });
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy();
    }
  }

  goToDate(event) {
    const newDate = event.detail.date;
    const currentDate = this.picker.getDate();
    if (newDate.toDateString() !== currentDate?.toDateString()) {
      this.picker.setDate(newDate);
    }
  }
}
