// Example usage:
// <div data-controller="tooltip" data-tooltip-content-value="Hello world"></div>
//
// Or using Tippy data attributes
// See: https://atomiks.github.io/tippyjs/v6/constructor/#attribute
//      https://atomiks.github.io/tippyjs/v6/customization/
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "~/controllers";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
  };

  connect() {
    this.setupTippy();
    this.setupObserver();
  }

  disconnect() {
    this.observer?.disconnect();
    this.tippy.destroy();
  }

  setupTippy = () => {
    let options = {
      arrow: false,
      offset: [0, 8],
      theme: "twelve",
    };
    if (this.hasContentValue) {
      options["content"] = this.contentValue;
    }
    this.tippy = tippy(this.element, options);
  };

  setupObserver = () => {
    this.observer = new MutationObserver(() => {
      this.tippy.destroy();
      this.setupTippy();
    });
    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: ["data-tippy-content", "data-tippy-placement", "data-tippy-trigger"],
    });
  };
}
