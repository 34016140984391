import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["input", "description"];

  connect = () => {
    this.codes = JSON.parse(this.element.dataset.cdtCodes);
    this.inputTarget.addEventListener("input", this.setDescription);
  };

  disconnect = () => {
    this.inputTarget.removeEventListener("input", this.setDescription);
    this.codes = null;
  };

  setDescription = (event) => {
    const { value } = event.target;
    const found = value && this.codes.find(([code]) => code === value);
    if (found) {
      this.descriptionTarget.value = found[1];
    }
  };
}
