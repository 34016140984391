import { Controller } from "~/controllers";
import { PopoverController } from "components/ui/popover_controller";

export default class extends Controller {
  connect = () => {
    this.handlePopoverClosed();
    this.element.addEventListener(PopoverController.onClose, this.handlePopoverClosed);
    this.element.addEventListener(PopoverController.onOpen, this.handlePopoverOpened);
  };

  disconnect = () => {
    this.element.removeEventListener(PopoverController.onClose, this.handlePopoverClosed);
    this.element.removeEventListener(PopoverController.onOpen, this.handlePopoverOpened);
  };

  handlePopoverClosed = () => {
    const inputs = this.element.querySelectorAll("input");
    inputs.forEach((input) => {
      input.disabled = true;
      input.checked = false;
    });
  };

  handlePopoverOpened = () => {
    const inputs = this.element.querySelectorAll("input");
    inputs.forEach((input) => (input.disabled = false));
  };
}
