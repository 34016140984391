import { Controller } from "~/controllers";

const FADE_HEIGHT = 40;
const MIN_OPACITY = 0.2;

// adds fade to top/bottom of scrollable element
// the intensity of the fade is based on how much the element can be scrolled in that direction
export default class extends Controller {
  static values = {
    position: { type: String, default: "top,bottom" },
  };

  connect() {
    this.updateScrollFade();
    this.element.addEventListener("scroll", () => this.updateScrollFade());
    this.resizeObserver = new ResizeObserver(() => {
      this.updateScrollFade();
    });
    this.resizeObserver.observe(this.element);
  }

  disconnect() {
    this.resizeObserver.disconnect();
  }

  updateScrollFade() {
    const { scrollHeight, clientHeight } = this.element;
    const maxScroll = scrollHeight - clientHeight;

    const reversed = getComputedStyle(this.element).flexDirection === "column-reverse";
    const scrollTop = reversed ? -1 * this.element.scrollTop : this.element.scrollTop;

    const startOpacity = this.positionValue.includes("top")
      ? this.getOpacity(scrollTop, maxScroll)
      : 1;
    const endOpacity = this.positionValue.includes("bottom")
      ? this.getOpacity(maxScroll - scrollTop, maxScroll)
      : 1;

    this.element.style.maskImage = `linear-gradient(
      to ${reversed ? "top" : "bottom"},
      rgba(0, 0, 0, ${startOpacity}),
      black ${FADE_HEIGHT}px,
      black calc(100% - ${FADE_HEIGHT}px),
      rgba(0, 0, 0, ${endOpacity})
    )`;
  }

  getOpacity(scroll, maxScroll) {
    return Math.max(1 - scroll / (maxScroll || 1), MIN_OPACITY);
  }
}
