import { Controller } from "~/controllers";

export default class extends Controller {
  remove = (event) => {
    if (this.element) {
      // if the currentTarget does other actions besides interacting w/ this controller, it needs to remain in dom for those actions to work
      // for example, if the currentTarget is a submit button, it needs to remain in dom for the form to submit
      const target = event.currentTarget;
      target.style.display = "none";
      this.element.replaceWith(target);
    }
  };
}
