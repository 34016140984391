import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["content", "icon"];

  toggle = () => {
    this.contentTarget.classList.toggle("hidden");
    this.iconTarget.classList.toggle("rotate-90");
  };
}
