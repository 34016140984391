import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["collapsedComments", "toggleButton", "toggleIcon", "toggleText"];

  toggleComments(event) {
    const btn = event.currentTarget;
    const eventId = btn.dataset.eventId;
    const totalComments = parseInt(btn.dataset.totalComments, 10);
    const collapsedComments = document.getElementById(`collapsed-comments-${eventId}`);
    const icon = btn.querySelector('[data-patient-history-target="toggleIcon"]');
    const text = btn.querySelector('[data-patient-history-target="toggleText"]');

    if (collapsedComments) {
      collapsedComments.classList.toggle("hidden");

      if (collapsedComments.classList.contains("hidden")) {
        icon.classList.remove("rotate-180");
        text.textContent = `Show ${totalComments - 2} more comments`;
      } else {
        icon.classList.add("rotate-180");
        text.textContent = "Hide comments";
      }
    }
  }
}
