export function toISODateString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/** Converts a date to the given timezone and returns the ISO string with timezone offset */
export function convertTimezone(date: Date, timezone: string): string {
  const padding = (num: number): string => num.toString().padStart(2, "0");
  const offset = getTimezoneOffset(date, timezone, padding);

  const year = date.getUTCFullYear();
  const month = padding(date.getUTCMonth() + 1);
  const day = padding(date.getUTCDate());
  const hours = padding(date.getUTCHours());
  const minutes = padding(date.getUTCMinutes());
  const seconds = padding(date.getUTCSeconds());

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offset}`;
}

function getTimezoneOffset(date: Date, timezone: string, padding: (num: number) => string): string {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timezone }));
  const offset = (tzDate.getTime() - utcDate.getTime()) / 60000;
  const sign = offset <= 0 ? "-" : "+";
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `${sign}${padding(hours)}:${padding(minutes)}`;
}
