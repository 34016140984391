import { Controller } from "~/controllers";

const EMPTY_VALUES = ["", null, undefined];

export default class extends Controller {
  submit() {
    if (!EMPTY_VALUES.includes(this.element.value)) {
      this.element.closest("form").submit();
    }
  }
}
