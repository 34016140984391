import { Controller } from "~/controllers";

export default class extends Controller {
  static values = {
    initial: String,
  };

  connect() {
    this.setProperties(this.initialValue);
  }

  change = (event) => {
    this.setProperties(event.params.change);
  };

  setProperties(properties) {
    properties.split(/\s+(?=--)/).forEach((set) => {
      const [name, value] = set.split(":", 2);
      this.element.style.setProperty(name, value);
    });
  }
}
