import { Controller } from "~/controllers";
import { createPopover } from "~/util/html/popover";
import { Turbo } from "@hotwired/turbo-rails";

const DEBOUNCE_MS = 300;

export default class extends Controller {
  static targets = ["input", "popover", "hiddenInput"];
  static values = {
    typeaheadUrl: String,
    typeaheadTurboFrameId: String,
    queryParam: String,
  };

  connect() {
    // so will render results if there is a value, w/o having to type new value
    if (this.inputTarget.value.length > 0) {
      this.submit();
    }
  }

  debouncedSubmit = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.submit, DEBOUNCE_MS);
  };

  submit = () => {
    Turbo.visit(
      `${this.typeaheadUrlValue}?${this.queryParamValue}=${this.inputTarget.value}&selected_patient_id=${this.hiddenInputTarget.value}`,
      {
        frame: this.typeaheadTurboFrameIdValue,
      },
    );
  };

  openPopover = () => {
    this.inputTarget.setAttribute("aria-expanded", "true");

    this.cleanup = createPopover({
      trigger: this.inputTarget,
      popover: this.popoverTarget,
    });
  };

  closePopover = () => {
    this.inputTarget.setAttribute("aria-expanded", "false");
    this.cleanup?.();
  };

  selectItem = (event) => {
    const { value, label } = event.params;
    this.hiddenInputTarget.value = value;
    this.inputTarget.value = label;
    this.inputTarget.blur();
    // so when popover opens next time it will be based on new value
    this.submit();
  };

  clearValue = () => {
    this.hiddenInputTarget.value = "";
  };
}
