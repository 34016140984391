import { Controller } from "~/controllers";

import { captureException } from "~/observability";
import { addFocusTrapEventListener, getFocusableElementsWithin } from "~/util/html/focus";
import { inertOtherBodyChildren } from "~/util/html/inert";

let isOpen = false;

export default class extends Controller {
  static targets = ["closeButton"];

  static values = {
    closeEndpoint: String,
  };

  connect = () => {
    this.element.addEventListener("transitionend", this.handleTransitionEnd);
    if (!isOpen) {
      this.element.dataset.open = "false";
    }
    this.open();
  };

  disconnect = () => {
    this.close();
    this.element.removeEventListener("transitionend", this.handleTransitionEnd);
  };

  open = () => {
    if (isOpen) {
      return;
    }
    isOpen = true;

    document.addEventListener("keydown", this.handleEscapeKey);
    this.disposeFocusTrap = addFocusTrapEventListener(() => this.element);

    const parent = this.element.parentElement;
    if (parent?.parentElement === document.body) {
      // Bit of defensive programming in case this element ever ends up somewhere weird
      this.disposeInertBody = inertOtherBodyChildren(parent);
    } else {
      captureException(new Error("Unexpected modal DOM"), {
        extra: {
          parent: parent?.tagName,
          parentParent: parent?.parentElement?.tagName,
        },
      });
    }

    // Save a reference to whatever is focused before opening the modal
    // so we can restore it later.
    this.focusedBeforeOpen = document.activeElement;
    getFocusableElementsWithin(this.element)[0]?.focus();

    const { documentElement } = document;
    Object.assign(documentElement.style, {
      // This is a calculation for the "width of a scrollbar" on OSs that have scrollbars. This
      // minor detail prevents layout shift when overflow is hidden. (It should be zero for OS
      // configurations without scrollbars).
      paddingRight: `${window.innerWidth - documentElement.clientWidth}px`,
      overflow: "hidden",
    });
    this.element.classList.remove("hidden");
    // setTimeout to ensure the display change has taken effect
    setTimeout(() => {
      this.element.dataset.open = "true";
    }, 0);
  };

  closeIfOutside = (event) => {
    if (event.target === this.element) {
      this.close();
    }
  };

  close = () => {
    if (!isOpen) {
      return;
    }
    isOpen = false;

    this.disposeInertBody?.();
    this.disposeFocusTrap();
    document.removeEventListener("keydown", this.handleEscapeKey);

    if (this.closeEndpointValue) {
      this.closeButtonTarget.click();
    }
    if (this.focusedBeforeOpen) {
      this.focusedBeforeOpen.focus({ preventScroll: true });
    }

    Object.assign(document.documentElement.style, { paddingRight: "", overflow: "" });
    this.element.dataset.open = "false";
  };

  handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      this.close();
    }
  };

  handleTransitionEnd = (event) => {
    if (event.propertyName === "opacity" && this.element.dataset.open === "false") {
      this.element.classList.add("hidden");
    }
  };
}
