import { Controller } from "~/controllers";
import Inputmask from "inputmask";

// Connects to data-controller="adjustment-form"
export default class extends Controller {
  static targets = [
    "error",
    "submit",
    "treatmentSelect",
    "amount",
    "amountLabel",
    "percentage",
    "percentageContainer",
    "signSelect",
  ];

  static values = {
    invoiceBalance: Number,
    invoiceCurrentAmount: Number,
    treatmentCosts: Object,
    currentTreatmentCosts: Object,
    invoiceOriginalTotalCost: Number,
    adjustmentTypes: Array,
  };

  connect = () => {
    this.isTreatmentAdjustment = false;
    this.submitted = false;
    this.valid = false;
    this.updateAdjustmentType();
    this.validateAmount();
    this.updateAmountLabel();

    Inputmask({
      alias: "percentage",
      clearMaskOnLostFocus: false,
      placeholder: "",
      allowMinus: false,
      rightAlign: false,
    }).mask(this.percentageTarget);
  };

  submitForm = (event) => {
    event.preventDefault();
    this.submitted = true;
    this.validateAmount();
    if (this.valid) {
      this.element.submit();
    }
  };

  updateAdjustmentType = () => {
    const selectedTypeId = parseInt(
      this.element.querySelector('select[name="adjustment[adjustment_type_id]"]').value,
    );

    const adjustmentType = this.adjustmentTypesValue.find((type) => type.id === selectedTypeId);

    if (!adjustmentType) {
      console.error("Adjustment type not found");
      return;
    }

    // Set the sign select value based on the adjustment type's sign
    if (this.hasSignSelectTarget) {
      this.signSelectTarget.value = adjustmentType.sign || 1;
    }

    if (adjustmentType.strategy === "percentage") {
      this.showPercentageInput(adjustmentType);
    } else {
      this.hidePercentageInput();
    }
    this.updateAmountLabel();
    this.validateAmount();
  };

  updateAmountLabel = () => {
    const sign = this.hasSignSelectTarget ? parseInt(this.signSelectTarget.value) : 1;
    const label = sign > 0 ? "(Positive)" : "(Negative)";
    this.amountLabelTarget.textContent = label;
  };

  showPercentageInput = (adjustmentType) => {
    this.percentageContainerTarget.classList.remove("hidden");

    const percentageInput = this.element.querySelector('input[name="adjustment[percentage]"]');
    const percentage = parseFloat(adjustmentType.default_amount) || percentageInput.value || 0;
    percentageInput.value = percentage;
    const baseAmount = this.getBaseAmount();
    const calculatedAmount = (baseAmount * (percentage / 100)).toFixed(2);
    this.amountTarget.value = calculatedAmount;
    this.amountTarget.disabled = true;

    percentageInput.addEventListener("input", this.updateAmountBasedOnPercentage);
  };

  hidePercentageInput = () => {
    this.percentageContainerTarget.classList.add("hidden");
    this.amountTarget.disabled = false;
  };

  updateAmountBasedOnPercentage = () => {
    const percentage = parseFloat(this.percentageTarget.value) || 0;
    const baseAmount = this.getBaseAmount();
    const calculatedAmount = (baseAmount * (percentage / 100)).toFixed(2);
    this.amountTarget.value = calculatedAmount;
    this.validateAmount();
  };

  getBaseAmount = () => {
    if (this.isTreatmentAdjustment && this.treatmentCostsValue) {
      const selectedTreatmentId = this.treatmentSelectTarget.value;
      return parseFloat(this.treatmentCostsValue[selectedTreatmentId]) || 0;
    } else {
      return this.invoiceOriginalTotalCostValue || 0;
    }
  };

  getCurrentAmount = () => {
    if (this.isTreatmentAdjustment && this.currentTreatmentCostsValue) {
      const selectedTreatmentId = this.treatmentSelectTarget.value;
      return parseFloat(this.currentTreatmentCostsValue[selectedTreatmentId]) || 0;
    } else {
      return this.invoiceCurrentAmountValue || 0;
    }
  };

  handleTreatmentChange = () => {
    const selectedTreatmentId = this.treatmentSelectTarget.value;
    this.isTreatmentAdjustment = selectedTreatmentId !== "";
    this.updateAdjustmentType();
    this.validateAmount();
  };

  validateAmount = () => {
    const maxAmount = this.getCurrentAmount();
    const amount = parseFloat(this.amountTarget.value);

    if (!amount) {
      if (this.submitted) {
        this.errorTarget.textContent = "Adjustment amount is required";
        this.errorTarget.classList.remove("hidden");
      }
      this.valid = false;
      return;
    }

    if (this.hasSignSelectTarget) {
      const sign = parseInt(this.signSelectTarget.value);
      if (sign > 0 && amount > 0) {
        // Allow arbitrary positive amounts
        this.errorTarget.classList.add("hidden");
        this.valid = true;
        return;
      }
    }

    if (amount < 0) {
      if (this.submitted) {
        this.errorTarget.textContent = `Adjustment amount must be between 0 and ${maxAmount.toFixed(2)}`;
        this.errorTarget.classList.remove("hidden");
      }
      this.valid = false;
    } else if (amount > maxAmount) {
      let adjustmentAmountErrorCopy = "invoice";
      if (this.isTreatmentAdjustment) {
        adjustmentAmountErrorCopy = "treatment";
      }
      if (this.submitted) {
        this.errorTarget.textContent = `Adjustment amount cannot exceed current ${adjustmentAmountErrorCopy} balance of ${maxAmount.toFixed(2)}`;
        this.errorTarget.classList.remove("hidden");
      }
      this.valid = false;
    } else {
      this.errorTarget.classList.add("hidden");
      this.valid = true;
    }
  };
}
