import { Calendar } from "@fullcalendar/core";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { Turbo } from "@hotwired/turbo-rails";
import { patch } from "@rails/request.js";

import { Controller } from "~/controllers";
import { toISODateString, convertTimezone } from "~/util/date";
import { formatUSD } from "~/util/format";
import { getSearchParam, deleteSearchParam, setSearchParam, UI_MODAL_FRAME } from "~/util/url";
import clsx from "~/util/html/style";

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = [
    "calendar",
    "dateButton",
    "dateDisplay",
    "pinnedAppointments",
    "timeBlocksLink",
    "dailyProduction",
    "operatory",
    "operatoryTemplate",
    "appointmentTemplate",
    "shortAppointmentTemplate",
  ];
  static values = {
    locationId: Number,
    locationTimezone: String,
    hipaaView: { type: Boolean, default: false },
    dailyProductionGoal: Number,
  };

  droppedEvents = [];
  appointmentsLoaded = false;
  operatoriesLoaded = false;

  connect() {
    this.initializeCalendar();
    this.initializePinnedAppointments();
    this.handlePatientIdParam();
    this.setupListeners();
  }

  disconnect() {
    window.removeEventListener("refresh-calendar", this.refreshCalendar);
  }

  setupListeners() {
    window.addEventListener("refresh-calendar", this.refreshCalendar);
  }

  refreshCalendar = (event) => {
    const date = event.detail?.date;
    if (!date || date === toISODateString(this.getCurrentDate())) {
      console.log("refetching resources and events");
      this.calendar.refetchResources();
      this.calendar.refetchEvents();
    }
  };

  initializeCalendar() {
    this.calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: "0300162430-fcs-1709102530",
      timeZone: this.locationTimezoneValue,
      plugins: [resourceTimeGridPlugin, interactionPlugin],
      initialView: "resourceTimeGridDay",
      initialDate: this.getInitialDate(),
      eventOverlap: (stillEvent, movingEvent) =>
        this.isTimeBlock(stillEvent) && this.isAppointment(movingEvent),
      nowIndicator: true,
      resources: `/settings/locations/${this.locationIdValue}/operatories.json`,
      resourceOrder: "order",
      resourceLabelContent: (arg) => this.renderOperatory(arg),
      editable: true,
      eventSources: [
        {
          url: `/locations/${this.locationIdValue}/appointments/calendar_events.json`,
          eventDataTransform: (event) => this.transformAppointmentData(event),
        },
        {
          url: `/locations/${this.locationIdValue}/time_blocks/calendar_time_blocks.json`,
          display: "background",
          color: "transparent",
          eventDataTransform: (event) => this.transformTimeBlockData(event),
        },
      ],
      headerToolbar: false,
      allDaySlot: false,
      scrollTime: "08:00:00",
      slotDuration: { minutes: 30 },
      snapDuration: { minutes: 10 }, // TODO: should be a schedule increment setting (10 or 15 min)
      slotLabelInterval: "01:00",
      slotMinTime: "08:00:00", // TODO: should be based on account/location setting
      slotMaxTime: "20:00:00", // TODO: should be based on account/location setting
      expandRows: true,
      height: "auto",
      stickyHeaderDates: "true",
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
      },
      dayHeaderFormat: { weekday: "short", day: "numeric" },
      eventDrop: (info) => this.handleEventDrop(info),
      eventResize: (info) => this.handleEventResize(info),
      eventClick: (info) => {
        if (this.isAppointment(info.event)) {
          this.handleAppointmentClick(info);
        }
      },
      eventDragStart: (info) => {
        if (this.isTimeBlock(info.event)) {
          return;
        }
        this.closeAppointmentPreview();
        const patientId = info.event.extendedProps.patientId;
        this.setPatientPanel(patientId);
        document.body.dataset.disableTooltip = "true";
      },
      eventDragStop: () => {
        delete document.body.dataset.disableTooltip;
      },
      eventContent: (arg) => {
        if (this.isTimeBlock(arg.event)) {
          return this.renderTimeBlock(arg);
        } else {
          return this.renderAppointment(arg);
        }
      },
      datesSet: () => {
        this.updateDateDisplay();
        this.closeAppointmentPreview();
        this.broadcastDateChanged();
        this.updateQueryParam();
        this.updateTimeBlocksLink();
        this.updateProduction();
      },
      droppable: true,
      eventReceive: (info) => {
        this.droppedEvents.push(info.event);
        this.updateAppointment(info.event);
      },
      eventSourceSuccess: () => {
        this.droppedEvents.forEach((event) => {
          event.remove();
        });
      },
      eventDidMount: (info) => {
        if (this.isTimeBlock(info.event)) {
          return;
        }
        // We should only need to check the events that mounted in the calendar since thats what the user is looking at
        if (info.event.id === this.getActiveAppointmentId()) {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get("open") === "true") {
            this.handleAppointmentClick(info);
            deleteSearchParam("open");
          }
        }
      },
      loading: (isLoading) => {
        if (isLoading) {
          this.appointmentsLoaded = false;
        }
      },
      eventsSet: (events) => {
        if (events.filter((event) => this.isAppointment(event)).length > 0) {
          this.appointmentsLoaded = true;
          this.updateProduction();
        }
      },
      resourcesSet: (resources) => {
        if (resources.length > 0) {
          this.operatoriesLoaded = true;
          this.updateProduction();
        }
      },
    });

    this.calendar.render();
  }

  broadcastDateChanged() {
    this.dispatch("date-changed", {
      detail: { date: this.getCurrentDate() },
    });
  }

  getDateParam() {
    return getSearchParam("date");
  }

  getInitialDate() {
    const dateParam = this.getDateParam();
    if (!dateParam || dateParam === "today") {
      return toISODateString(new Date());
    }
    return dateParam;
  }

  handlePatientIdParam() {
    const patientId = getSearchParam("patient_id");
    if (patientId) {
      deleteSearchParam("patient_id");
      this.setPatientPanel(patientId);
    }
  }

  updateQueryParam() {
    const currentDate = toISODateString(this.getCurrentDate());
    const today = toISODateString(new Date());

    const param = currentDate === today ? "today" : currentDate;

    setSearchParam("date", param);
  }

  updateTimeBlocksLink() {
    const currentDate = toISODateString(this.getCurrentDate());
    const url = new URL(this.timeBlocksLinkTarget.href);
    url.searchParams.set("date", currentDate);
    this.timeBlocksLinkTarget.href = url.toString();
  }

  updateProduction = () => {
    if (!this.appointmentsLoaded || !this.operatoriesLoaded) {
      return;
    }

    const currentDate = toISODateString(this.getCurrentDate());

    const events = this.calendar.getEvents();
    const dailyEvents = events.filter(
      (event) => this.isAppointment(event) && toISODateString(event.start) === currentDate,
    );
    const dailyProduction = dailyEvents.reduce(
      (sum, event) => sum + (event.extendedProps.netProduction || 0),
      0,
    );

    if (this.dailyProductionGoalValue) {
      this.dailyProductionTarget.querySelector("[data-production-text]").textContent =
        `${formatUSD(dailyProduction, { round: true })} / ${formatUSD(this.dailyProductionGoalValue, { round: true })}`;
      this.dailyProductionTarget.dataset.goalMet = dailyProduction >= this.dailyProductionGoalValue;
    }

    this.calendar.getResources().forEach((resource) => {
      const resourceProduction = dailyEvents
        .filter((event) => event.getResources().some((r) => r.id === resource.id))
        .reduce((sum, event) => sum + (event.extendedProps.netProduction || 0), 0);
      const operatory = this.operatoryTargets.find(
        (target) => target.dataset.resourceId === resource.id,
      );
      operatory.dataset.tippyContent = formatUSD(resourceProduction, {
        round: true,
      });
      operatory.dataset.controller = "tooltip";
    });
  };

  toggleHipaaView() {
    this.hipaaViewValue = !this.hipaaViewValue;
    this.rerenderCalendar();
  }

  toggleChecklistView() {
    this.element.dataset.showChecklist = this.element.dataset.showChecklist !== "true";
  }

  setPatientPanelAction = (event) => {
    const patientId = event.params.patientId;
    this.setPatientPanel(patientId);
  };

  setPatientPanel = (patientId) => {
    Turbo.visit(`/patients/${patientId}/panel`, { frame: "panel_frame" });
    this.rerenderCalendar();
  };

  setActiveAppointmentId(appointmentId) {
    const url = new URL(window.location);
    if (appointmentId) {
      url.searchParams.set("appointment_id", appointmentId);
    } else {
      url.searchParams.delete("appointment_id");
    }
    window.history.replaceState({}, "", url);
    this.rerenderCalendar();
  }

  handleEventDrop = (info) => {
    this.updateAppointment(info.event);
  };

  handleEventResize = (info) => {
    this.updateAppointment(info.event);
  };

  updateAppointment = (event) => {
    const appointmentId = event.id;
    const resourceId = event.getResources()[0].id;
    // Used for when fullcalendar returns a date without timezone offset (i.e. when rescheduling an appointment)
    const startTimeWithTimezone = convertTimezone(event.start, this.locationTimezoneValue);

    return patch(`/appointments/${appointmentId}/schedule`, {
      body: {
        appointment: {
          start_time: startTimeWithTimezone,
          duration_minutes: Math.round((event.end - event.start) / (1000 * 60)),
          operatory_id: resourceId,
        },
      },
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text)
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  };

  goToDate(event) {
    const newDate = event.detail.date;
    const currentDate = this.getCurrentDate();
    if (newDate.toDateString() !== currentDate?.toDateString()) {
      this.calendar.gotoDate(newDate);
    }
  }

  // Full calendar returns back a date with the timezone offset set from initialization so we need
  // to adjust for the timezone offset of the user's browser
  getCurrentDate = () => {
    let currentDate = this.calendar.getDate();
    const timezoneOffset = currentDate.getTimezoneOffset();
    const adjustedDate = new Date(currentDate.getTime() + timezoneOffset * 60000);
    return adjustedDate;
  };

  updateDateDisplay() {
    const currentDate = this.getCurrentDate();
    let formattedDate;
    if (currentDate.toDateString() === new Date().toDateString()) {
      formattedDate = `Today, ${currentDate.toLocaleString("default", { month: "short", day: "numeric" })}`;
    } else {
      formattedDate = currentDate.toLocaleString("default", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
    }
    this.dateDisplayTarget.textContent = formattedDate;
  }

  eventData(info) {
    return {
      "appointment[start_time]": info.event.start,
      "appointment[end_time]": info.event.end,
      "appointment[operatory_id]": info.event.getResources()[0].id,
      "appointment[status]": info.event.status,
    };
  }

  closeAppointmentPreview() {
    // TODO: figure out proper way to clear
    const previewDialog = document.getElementById("calendar_appointment_preview_frame");
    if (previewDialog) {
      previewDialog.innerHTML = "";
    }
  }

  refetchEvents() {
    this.calendar.refetchEvents();
  }

  rerenderCalendar() {
    // Immediately forces the calendar to render and/or readjusts its size
    this.calendar.render();
    setTimeout(this.updateProduction, 0);
  }

  goToToday() {
    this.calendar.today();
  }

  next() {
    this.calendar.next();
  }

  prev() {
    this.calendar.prev();
  }

  getActiveAppointmentId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("appointment_id");
  }

  initializePinnedAppointments() {
    new Draggable(this.pinnedAppointmentsTarget, {
      itemSelector: "[data-pinned-appointment]",
    });
  }

  renderOperatory = (arg) => {
    const root = this.operatoryTemplateTarget.content.firstElementChild.cloneNode(true);

    root.action = root.getAttribute("action").replace("ID_HERE", arg.resource.id);
    root.querySelector("[data-operatory-name]").textContent = arg.resource.title;
    root.dataset.alert = arg.resource.extendedProps.alert;
    root.dataset.resourceId = arg.resource.id;

    return { domNodes: [root] };
  };

  renderTimeBlock(arg) {
    const classes = clsx(
      "px-4 py-2 flex-col justify-between flex overflow-hidden h-full w-full bg-[--time-block-color]",
      !arg.event.url && "opacity-50",
    );
    const content = `
      <div
        style="--time-block-color: ${arg.event.extendedProps.color}"
        class="${classes}"
      >
        <div class="text-sm font-medium text-typography-primary truncate">${arg.event.title}</div>
        <div class="text-xs truncate">${arg.timeText}</div>
      </div>
    `;

    if (arg.event.url) {
      return {
        html: `
          <a
            href="${arg.event.url}"
            data-turbo-frame="ui-modal"
            title="${arg.event.title}"
            class="transition duration-300 ease-in-out hover:shadow-md block h-full"
          >
            ${content}
          </a>
        `,
      };
    }

    return { html: content };
  }

  renderAppointment(arg) {
    const eventDuration = arg.event.end.getTime() - arg.event.start.getTime();
    const isShortEvent = eventDuration <= 30 * 60 * 1000; // 30 minutes in milliseconds
    const template = isShortEvent
      ? this.shortAppointmentTemplateTarget
      : this.appointmentTemplateTarget;
    const root = template.content.firstElementChild.cloneNode(true);

    const isActive = arg.event.id === this.getActiveAppointmentId();
    root.dataset.active = isActive;
    if (isActive) {
      root.ariaCurrent = "true";
    }
    root.dataset.pinned = arg.event.extendedProps.isPinned;
    root.dataset.hasTasks = arg.event.extendedProps.hasTasks;
    root.dataset.status = arg.event.extendedProps.status;
    root.style = `--appointment-color: ${arg.event.extendedProps.backgroundColorHex}`;

    if (!this.hipaaViewValue) {
      const nameElement = root.querySelector("[data-appointment-name]");
      nameElement.textContent = arg.event.extendedProps.patientName;
    }

    const { procedures } = arg.event.extendedProps;
    if (procedures) {
      const proceduresElement = root.querySelector("[data-appointment-procedures]");
      proceduresElement.textContent = procedures;
      proceduresElement.dataset.tippyContent = procedures;
      proceduresElement.dataset.controller = "tooltip";
    }

    if (!isShortEvent) {
      const timeElement = root.querySelector("[data-appointment-time]");
      timeElement.textContent = arg.timeText;

      const productionElement = root.querySelector("[data-appointment-production]");
      productionElement.textContent = formatUSD(arg.event.extendedProps.netProduction, {
        round: true,
      });
    }

    const notesIconElement = root.querySelector("[data-notes-icon]");
    const { notes } = arg.event.extendedProps;
    if (notes) {
      notesIconElement.dataset.tippyContent = notes;
    } else {
      notesIconElement.remove();
    }

    if (!arg.event.extendedProps.waitlist) {
      root.querySelector("[data-waitlist-icon]").remove();
    }

    if (!arg.event.extendedProps.isNewPatient) {
      root.querySelector("[data-new-patient-icon]").remove();
    }

    const medicalAlertsIconElement = root.querySelector("[data-medical-alerts-icon]");
    const { medicalAlerts } = arg.event.extendedProps;
    if (medicalAlerts.length > 0) {
      medicalAlertsIconElement.dataset.tippyContent = medicalAlerts.join("\n\n");
    } else {
      medicalAlertsIconElement.remove();
    }

    const providerElements = root.querySelectorAll("[data-appointment-provider-icon]");
    providerElements.forEach((element) => {
      const providerId = parseInt(element.dataset.appointmentProviderIcon);
      if (!arg.event.extendedProps.providerIds.includes(providerId)) {
        element.remove();
      }
    });

    const checklistProgressIndicator = root.querySelector(
      "[data-appointment-checklist-progress-indicator]",
    );
    checklistProgressIndicator.addEventListener("click", (event) => {
      event.preventDefault();
      this.setPatientPanel(arg.event.extendedProps.patientId);
      Turbo.visit(`/appointments/${arg.event.id}/checklist?modal=true`, { frame: UI_MODAL_FRAME });
    });
    function setFilledPercentage(bar) {
      const progressBar = root.querySelector(
        `[data-appointment-checklist-progress-indicator-${bar}]`,
      );
      const percentage = arg.event.extendedProps.checklistProgress[bar];
      progressBar.style.setProperty("--filled-percentage", `${percentage}%`);
    }
    setFilledPercentage("before");
    setFilledPercentage("during");
    setFilledPercentage("after");

    return { domNodes: [root] };
  }

  handleAppointmentClick(info) {
    info.jsEvent?.preventDefault();

    this.positionDialog(info);
    this.loadAppointmentPreview(info);
    this.updatePatientPanel(info);
  }

  positionDialog(info) {
    const dialogContainer = document.getElementById("calendar_appointment_preview_frame");

    const positionDialog = (initialRender) => {
      if (initialRender) {
        // do initial render while hidden so div can get sized
        dialogContainer.style.visibility = "visible";
        dialogContainer.classList.remove("transition-opacity");
        dialogContainer.classList.add("opacity-0");

        function frameLoadHandler(event) {
          if (event.target.id === "calendar_appointment_preview_frame") {
            positionDialog();
            document.removeEventListener("turbo:frame-load", frameLoadHandler);
          }
        }
        document.addEventListener("turbo:frame-load", frameLoadHandler);
        return;
      }

      const { left, top } = this.calculateDialogPosition(info, dialogContainer);

      dialogContainer.style.position = "fixed";
      dialogContainer.style.left = `${left}px`;
      dialogContainer.style.top = `${top}px`;
      dialogContainer.classList.add("transition-opacity");
      dialogContainer.classList.remove("opacity-0");
    };

    positionDialog(true);
  }

  calculateDialogPosition(info, dialogContainer) {
    const calendarRect = this.element.getBoundingClientRect();
    const eventRect = info.el.getBoundingClientRect();
    const dialogHeight = dialogContainer.offsetHeight;
    const dialogWidth = dialogContainer.offsetWidth;

    // Position the dialog to the side of the event with more space
    const relativeLeft = eventRect.left - calendarRect.left;
    const relativeRight = eventRect.right - calendarRect.left;
    const spaceLeft = relativeLeft;
    const spaceRight = calendarRect.width - relativeRight;
    const buffer = 12;
    let left;
    if (spaceRight > spaceLeft) {
      // Position to the right of the event
      left = eventRect.right + buffer;
    } else {
      // Position to the left of the event
      left = eventRect.left - dialogWidth - buffer;
    }

    // Center the dialog with the event
    const relativeTop = eventRect.top - calendarRect.top;
    const eventCenter = relativeTop + eventRect.height / 2;
    const dialogHalfHeight = dialogHeight / 2;
    let top = eventCenter - dialogHalfHeight;
    if (top < calendarRect.top) {
      // Prevent going above calendar
      top = calendarRect.top;
    } else if (top + dialogHeight > calendarRect.bottom) {
      // Prevent going below calendar
      top = calendarRect.bottom - dialogHeight;
    }

    return { left, top };
  }

  loadAppointmentPreview(info) {
    Turbo.visit(info.event.url, { frame: "calendar_appointment_preview_frame" });
  }

  updatePatientPanel(info) {
    const patientId = info.event.extendedProps.patientId;
    this.setPatientPanel(patientId);
    this.setActiveAppointmentId(info.event.id);
  }

  transformAppointmentData(appointment) {
    return {
      id: appointment.id,
      title: appointment.patientName,
      start: appointment.start,
      end: appointment.end,
      allDay: appointment.allDay,
      resourceId: appointment.resourceId,
      url: appointment.previewUrl,
      extendedProps: {
        eventType: appointment.eventType,
        backgroundColorHex: appointment.backgroundColorHex,
        hasTasks: appointment.hasTasks,
        isPinned: appointment.isPinned,
        notes: appointment.notes,
        patientId: appointment.patientId,
        patientName: appointment.patientName,
        procedures: appointment.procedures,
        status: appointment.status,
        waitlist: appointment.waitlist,
        netProduction: appointment.netProduction,
        isNewPatient: appointment.isNewPatient,
        medicalAlerts: appointment.medicalAlerts,
        providerIds: appointment.providerIds,
        checklistProgress: appointment.checklistProgress,
      },
    };
  }

  transformTimeBlockData(timeBlock) {
    return {
      id: timeBlock.id,
      title: timeBlock.name,
      start: timeBlock.start,
      end: timeBlock.end,
      allDay: timeBlock.allDay,
      resourceIds: timeBlock.resourceIds,
      url: timeBlock.editUrl,
      extendedProps: {
        eventType: timeBlock.eventType,
        color: timeBlock.color,
      },
    };
  }

  isAppointment(event) {
    return event.extendedProps.eventType === "appointment";
  }

  isTimeBlock(event) {
    return event.extendedProps.eventType === "time_block";
  }
}
