import { Controller } from "~/controllers";
import { post } from "@rails/request.js";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = {
    taskId: Number,
    locationId: Number,
  };

  connect() {
    this.element.addEventListener("click", () => {
      // TODO: make helper for making requests and handling turbo-stream responses (use in calendar_controller as well)
      post(`/locations/${this.locationIdValue}/tasks/${this.taskIdValue}/set_active`, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      })
        .then((response) => response.text)
        .then((html) => {
          Turbo.renderStreamMessage(html);
        });
    });
  }
}
