/**
 * Compares two URL strings to check if they represent the same page.
 * @param {string} url1 - The first URL string to compare.
 * @param {string} url2 - The second URL string to compare.
 * @param {boolean} checkParameters - Whether to check query parameters.
 * @returns {boolean} True if the URLs represent the same page, false otherwise.
 */
export const isSamePage = (url1: string, url2: string, checkParameters = false) => {
  let decodedUrl1 = decodeURIComponent(url1);
  let decodedUrl2 = decodeURIComponent(url2);

  if (!checkParameters) {
    decodedUrl1 = decodedUrl1.split("?")[0]!;
    decodedUrl2 = decodedUrl2.split("?")[0]!;
  }

  // If one URL is a relative URL, make both relative
  if (!decodedUrl1.startsWith("http") || !decodedUrl2.startsWith("http")) {
    const makeRelative = (url: string) => {
      if (!url.startsWith("http")) {
        return url.startsWith("/") ? url : `/${url}`;
      }
      return new URL(url).pathname;
    };
    decodedUrl1 = makeRelative(decodedUrl1);
    decodedUrl2 = makeRelative(decodedUrl2);
  }

  // Remove trailing slashes
  decodedUrl1 = decodedUrl1.replace(/\/$/, "");
  decodedUrl2 = decodedUrl2.replace(/\/$/, "");

  return decodedUrl1 === decodedUrl2;
};

export const UI_MODAL_FRAME = "ui-modal";

export function getSearchParam(param: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export function deleteSearchParam(param: string) {
  const url = new URL(window.location.toString());
  url.searchParams.delete(param);
  window.history.replaceState({}, "", url);
}

export function setSearchParam(param: string, value: string) {
  const url = new URL(window.location.toString());
  url.searchParams.set(param, value);
  window.history.replaceState({}, "", url);
}
