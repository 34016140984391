import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["input", "value", "button"];

  updateInterval = () => {
    const interval = {};
    this.inputTargets.forEach((input) => {
      const value = parseInt(input.value, 10);
      if (value > 0) {
        interval[input.dataset.unit] = value;
      }
    });
    this.valueTarget.value = JSON.stringify(interval);
    this.buttonTarget.innerHTML = this.text();
  };

  text = () => {
    const sorted = Object.entries(JSON.parse(this.valueTarget.value)).sort(
      (a, b) => ["y", "m", "w", "d"].indexOf(a) - ["y", "m", "w", "d"].indexOf(b),
    );
    const text = sorted.map(([key, value]) => `${value}${key}`).join(" ");
    return text.length > 0 ? text : "0d";
  };
}
